import { Spinner } from "@amzn/awsui-components-react";
import { useMemo, useState } from "react";

export interface AsyncComponentProps {
    fetchComponent: (...any) => Promise<JSX.Element | null>;
    data: any;
}

export const AsyncComponent = (props: AsyncComponentProps) => {
    const [component, setComponent] = useState<JSX.Element | null>(<Spinner />);
    useMemo(() => props.fetchComponent(...props.data).then((component: JSX.Element | null) => {
        setComponent(component);
    }).catch((error: any) => setComponent(null)), [props.data]);

    return component;
}