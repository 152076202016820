import {
  Box,
  Container, Grid,
  Link
} from '@amzn/awsui-components-react';
import { AnalyticsEvent, AnalyticsEventType, PHONETOOL_LINK, Salesforce_LINK } from '../../core/constants';
import { Engagement } from '../../core/types';
import { EngagementStatusLabel, SuitcaseIcon, ValueWithLabel } from '../shared';
import './DetailHeader.css';

export interface DetailHeaderProps {
  engagement: Engagement;
}

export const DetailHeader = (props: DetailHeaderProps) => {
  const managerValue: string | undefined = props.engagement.managerFullName ?
    `${props.engagement.managerFullName}, ${props.engagement.manager}` :
    props.engagement.manager

  return (
    <Container>
      <Grid gridDefinition={[
        { colspan: 3 },
        { colspan: 2 },
        { colspan: 3 },
        { colspan: 2 },
        { colspan: 1 },
        { colspan: 1 }
      ]}>
        <div className='details-page-header'>
          <div>
            <SuitcaseIcon />
          </div>
          <Box fontSize="heading-l" fontWeight="bold">
            {props.engagement.name}
          </Box>
        </div>
        <ValueWithLabel label='Status' value={props.engagement.stage}>
          <EngagementStatusLabel engagement={props.engagement} />
        </ValueWithLabel>
        <ValueWithLabel label='Manager' value={managerValue}>
          <Link
            href={PHONETOOL_LINK(props.engagement.manager)}
            external={true}
            data-csm-on='click'
            data-csm-name={AnalyticsEventType.LinkClick}
            data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}
          >
            {managerValue}
          </Link>
        </ValueWithLabel>
        <ValueWithLabel
          label='SFDC Project Link'
          value={<Link
            href={Salesforce_LINK(props.engagement.sfdcId)}
            external={true}
            data-csm-on='click'
            data-csm-name={AnalyticsEventType.LinkClick}
            data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${AnalyticsEvent.SFDCProjectLink}`}
          >
            {props.engagement.projectId}
          </Link>}
        />
        <ValueWithLabel label='Start Date' value={props.engagement.startDate} />
        <ValueWithLabel label='End Date' value={props.engagement.endDate} />
      </Grid>
    </Container>
  );
};
