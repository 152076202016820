import {Box, Grid, Header, Link,} from '@amzn/awsui-components-react';
import {useAppContext} from '../../../AppContext';
import * as TableConstants from '../../../core/constants';
import {Engagement} from '../../../core/types';
import {UserType} from '../../../core/types/user';
import {User} from '../../../core/user';
import {isEngagementDeleteable} from '../../../core/utils/engagement-utils/EngagementUtils';
import {
    AsyncComponent,
    EngagementStatusLabel,
    ExpandableTable,
    InternalLink,
    SuitcaseIcon,
    ValueWithLabel
} from '../../shared';
import {CTIRGStatusLabel} from '../../shared/cti-rg-status-label';
import { TableFilterToggle } from '../../shared/filter-toggle';
import {ResourceDeletionModal} from '../../shared/resource-deletion-modal';
import './EngagementsTable.css';
import {EngagementTableFilter, FilterPredicate} from './TableFilter';
import {getRgLink, ticketHeader} from "../../../helpers/tickets";
import {ExpandedEngagementDetail} from "../../engagement-detail/ExpandedEngagementDetail";


export interface EngagementsTableProps {
  items: Engagement[];
  loading: boolean;
  tableType: UserType;
  filterToggleChecked: boolean;
  filter: (newFilter: EngagementTableFilter, type?: UserType) => void;
  loadData: (refresh?: boolean) => void;
}

export const EngagementsTable = (props: EngagementsTableProps) => {
  const { client, featureFlags } = useAppContext();

  let user: User = new User();
  let header: string = TableConstants.RESOURCE_ENGAGEMENTS_TABLE_TITLE;
  let headerDescription: string = TableConstants.RESOURCE_ENGAGEMENTS_TABLE_DESCRIPTION;
  let tableId = 'resource-engagement-table';
  let toggleId = 'resource-table-filter-toggle';

  if (props.tableType === UserType.MANAGER) {
    header = TableConstants.MANAGED_ENGAGEMENTS_TABLE_TITLE;
    headerDescription = TableConstants.MANAGED_ENGAGEMENTS_TABLE_DESCRIPTION;
    tableId = 'manager-engagement-table';
    toggleId = 'manager-table-filter-toggle';
  }

  const confirmDeletion = async () => {
    // Refresh page
    await props.loadData(true);
  }

  return (
    <ExpandableTable
      id={tableId}
      header={
        <div>
          <Header description={headerDescription} counter={`(${props.items.length})`}>
            {header}
          </Header>
          <TableFilterToggle
            id={toggleId}
            checked={props.filterToggleChecked}
            // TODO: TODO: Remove the ternary operators once EHI Active feature has been approved.
            // [Flag - ff-frontend-ehi-active-20230221]
            displayOnText={featureFlags["ff-frontend-ehi-active-20230221"]?.enabled
                ? TableConstants.ENGAGEMENTS_TABLE_TOGGLE_ON_TEXT_FF
                : TableConstants.ENGAGEMENTS_TABLE_TOGGLE_ON_TEXT
          }
            displayOffText={featureFlags["ff-frontend-ehi-active-20230221"]?.enabled
                ? TableConstants.ENGAGEMENTS_TABLE_TOGGLE_OFF_TEXT_FF
                : TableConstants.ENGAGEMENTS_TABLE_TOGGLE_OFF_TEXT
          }
            filter={props.filter}
            offFilter={ new EngagementTableFilter(
                    FilterPredicate.Active,
                    featureFlags["ff-frontend-ehi-active-20230221"]?.enabled as boolean
                )}
            onFilter={ new EngagementTableFilter(FilterPredicate.All) }
            tableType={props.tableType}
            padding={{ top: "s", bottom: 's', right: 's' }}
          />
        </div>
      }
      className='engagements-table'
      loading={props.loading}
      loadingText={TableConstants.ENGAGEMENTS_TABLE_LOADING_TEXT}
      items={props.items}
      empty={
        <Box textAlign="center" color="inherit">
          No engagements
        </Box>
      }
      getRowIcon={(engagement: Engagement) => <SuitcaseIcon />}
      getHeaderContent={(engagement: Engagement) => (
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 1 }, { colspan: 1 }]}>
          <Box padding='n' margin='n' variant='p'>
            <ValueWithLabel
              label='Engagement'
              value={<InternalLink
                id={`eng-table-id-${engagement.projectId}`}
                href={`engagements/${engagement.projectId}`}
                data-csm-on='click'
                data-csm-name={TableConstants.AnalyticsEventType.ButtonClick}
                data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${TableConstants.AnalyticsEvent.DetailPageButton}`}
              >{engagement.name}</InternalLink>
              }
            />
          </Box>
          <Box padding='n' margin='n' variant='p'>
            <ValueWithLabel
              label='SFDC Project Link'
              value={<Link
                href={TableConstants.Salesforce_LINK(engagement.sfdcId)}
                external={true}
                data-csm-on='click'
                data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${TableConstants.AnalyticsEvent.SFDCProjectLink}`}
              >
                {engagement.projectId}
              </Link>}
            />
          </Box>
          <Box padding='n' margin='n' variant='p'>
            <ValueWithLabel label='Status' value={engagement.stage}>
              <EngagementStatusLabel engagement={engagement} />
            </ValueWithLabel>
          </Box>
          <Box padding='n' margin='n' variant='p'>
            <ValueWithLabel label='CTI/Resolver Group Status' value={engagement.activeCTI ? 'Active' : 'Deleted'}>
              { featureFlags['engagement_closure']?.enabled && isEngagementDeleteable(user.userId, engagement)
                ?
                  <ResourceDeletionModal
                    confirm={confirmDeletion}
                    engagement={engagement}
                    isRenderedAsIcon={true}/>
                :
                  <CTIRGStatusLabel engagement={engagement}/>
              }
            </ValueWithLabel>
          </Box>
          <Box padding='n' margin='n' variant='p'>
              {/* Link would not appear when it was set to value so moved outside*/}
              <ValueWithLabel
                  label={ticketHeader}
                  value={' '}>
                  <AsyncComponent data={[client, engagement.projectId]} fetchComponent={getRgLink} />
              </ValueWithLabel>
          </Box>
          <Box padding='n' margin='n' variant='p'>
            <ValueWithLabel label='Start Date' value={engagement.startDate} />
          </Box>
          <Box padding='n' margin='n' variant='p'>
            <ValueWithLabel label='End Date' value={engagement.endDate} />
          </Box>
        </Grid>)}
      getExpandedContent={async (engagement: Engagement) => (ExpandedEngagementDetail({engagement: engagement}))}
    />
  );
};
