import {
  Box, Flashbar, SpaceBetween,
  Tabs
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { Engagement } from '../../core/types';
import { ModifyResourcesProps } from '../../core/types/ModifyResourcesProps';
import { RGMember } from '../../core/types/RGMember';
import { DetailHeader } from './DetailHeader';
import { DetailsTab, ResourcesTab } from './tabs';
import { QueryWorkflowsBanners } from '../shared/workflow_query';
import { useAppContext } from "../../AppContext";

export interface EngagementDetailProps extends ModifyResourcesProps {
  engagement: Engagement;
  loading: boolean;
  loadData: () => void;
  getRgData: () => RGMember[];
}

export const EngagementDetail = (props: EngagementDetailProps) => {
  const { featureFlags } = useAppContext();

  function displayRequestError(errorMessage: string) {
    setErrorMessage(errorMessage);
  }

  function dismissError() {
    setErrorMessage("");
  }

  let confirmDeletion = async () => {
    // Refresh page
    await props.loadData();
  }

  const [errorMessage, setErrorMessage] = useState<string>("");
  return (
    <div>
      {featureFlags["workflow_status"]?.enabled && <QueryWorkflowsBanners engagementId={props.engagement.id} refreshResources={props.refreshResources}/>}
      {
        errorMessage !== '' && <Flashbar items={[
          {
            dismissible: true,
            onDismiss: dismissError,
            content: <Box>${errorMessage}</Box>,
            type: 'error'
          }
        ]} />
      }


      <SpaceBetween
        direction='vertical'
        size='l'
      >
        <Box variant='h1'>
          <DetailHeader engagement={props.engagement} />
        </Box>
        <Box>
          <Tabs
            tabs={[
              {
                label: 'Details',
                id: 'details',
                content: <DetailsTab {...props} confirmDeletion={confirmDeletion} />
              },
              {
                label: 'Resolver Group',
                id: 'resources',
                content: <ResourcesTab
                  addToUsedResources={props.addToUsedResources}
                  displayRequestError={displayRequestError}
                  engagement={props.engagement}
                  getRemainingResources={props.getRemainingResources}
                  getRgData={props.getRgData}
                  loading={props.loading}
                  refreshResources={props.refreshResources}
                  removeFromUsedResources={props.removeFromUsedResources}
                  loadData={props.loadData}
                  confirmDeletion={confirmDeletion} />
              }
            ]}
          />
        </Box>
      </SpaceBetween>
    </div>
  );
};
