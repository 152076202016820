import {
    Box, ColumnLayout,
    Container,
    Header,
    Link
} from '@amzn/awsui-components-react';
import {
    AESA_PSQ_LINK,
    AnalyticsEvent,
    AnalyticsEventType, DETAIL_PAGE_TICKET_CONTAINER_DESCRIPTION,
    DETAIL_PAGE_TICKET_CONTAINER_TITLE,
    DSR_LINK, RG_Tickets_LINK,
    SSP_LINK
} from '../../../core/constants';
import { useEngagementDetailPageContext } from '../../../pages/EngagementDetailPageContext';

export const TicketsContainer = () => {
    const { engagement } = useEngagementDetailPageContext();

    return (
        <Container
            data-testid='RGTicketsContainer'
            header={
                <Header
                    variant="h2"
                    description={DETAIL_PAGE_TICKET_CONTAINER_DESCRIPTION}
                >
                    {DETAIL_PAGE_TICKET_CONTAINER_TITLE}
                </Header>
            }
        >
            <ColumnLayout borders='horizontal' columns={1}>
                <Box padding='n' margin='n' variant='p'>
                    <Link
                        href={AESA_PSQ_LINK(engagement)}
                        external={true}
                        data-csm-on='click'
                        data-csm-name={AnalyticsEventType.LinkClick}
                        data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.AESAPSQTicketsLink}`}
                    >
                        AESA/PSQ Tickets
                    </Link>
                </Box>
                <Box padding='n' margin='n' variant='p'>
                    <Link
                        href={DSR_LINK(engagement)}
                        external={true}
                        data-csm-on='click'
                        data-csm-name={AnalyticsEventType.LinkClick}
                        data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.DSRTicketsLink}`}
                    >
                        Deliverable Security Review
                    </Link>
                </Box>
                <Box padding='n' margin='n' variant='p'>
                    <Link
                        href={SSP_LINK(engagement)}
                        external={true}
                        data-csm-on='click'
                        data-csm-name={AnalyticsEventType.LinkClick}
                        data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.SSPTicketsLink}`}
                    >
                        Subcontractor Security Package
                    </Link>
                </Box>
            </ColumnLayout>
        </Container>
    );
}
