import { Box, Button, Header, Icon, Link, Modal, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import {useMemo, useState, Fragment, useEffect} from "react";
import { useAppContext } from "../../../AppContext";
import * as TableConstants from "../../../core/constants";
import { AnalyticsEvent, pinpointLinkDataAttrs } from "../../../core/constants";
import { Engagement } from "../../../core/types";
import { CTIRGStatusLabel } from "../cti-rg-status-label";

export interface ResourceDeletionModalProps {
    engagement: Engagement;
    isRenderedAsIcon: boolean;
    ctiName?: string;
    confirm?: () => void;
    cancel?: () => void;
    // ModalOnly set to true will not display the opening button which controls visibility. If this is set to true,
    // then the visible and setVisibility props need to be passed in
    // to control when modal should be visible or not.
    modalOnly?: boolean;
    visible?: boolean;
    setVisibility?: (visible: boolean) => void;
}

export const ResourceDeletionModal = (props: ResourceDeletionModalProps) => {
    const [visible, setVisible] = useState<boolean>(props.visible?? false);
    const [confirming, setConfirming] = useState<boolean>(false);
    const [cti, setCTI] = useState<string | undefined>(props.ctiName);
    const [rg, setRg] = useState<string | undefined>(props.engagement.primaryResolverGroup);
    const { client, appendNotification } = useAppContext();

    const engagementNameForMessaging = props.isRenderedAsIcon ? <Fragment>engagement <b>{props.engagement.name}</b></Fragment> : <Fragment>this engagement</Fragment>;

    let confirmDelete = async () => {
        setConfirming(true);
        client.delete(`/ts/engagements/${props.engagement.id}`).then(async () => {
            if (props.confirm) {
                await props.confirm();
            }

            // Display success flashbar
            appendNotification({
                content: (
                <Fragment>
                    The CTI <b>{cti}</b> and Resolver Group <b>{rg}</b> for {engagementNameForMessaging} were successfully deleted.
                </Fragment>
                ),
                type: 'success',
                dismissible: true,
                dismissLabel: 'Dismiss message',
        
            });
            setVisible(false);;

        }).catch((error) => {
            // Generic error message
            let errorMessage =  <Fragment>
                                    A problem occurred while removing the CTI <b>{cti}</b> and Resolver Group <b>{rg}</b> for {engagementNameForMessaging}
                                </Fragment>;

            // Modify error message if CTI status failed to remove (500)
            if (error.message?.includes('500')) {
                errorMessage =  <Fragment>
                                    The CTI <b>{cti}</b> and Resolver Group <b>{rg}</b> for {engagementNameForMessaging} were successfully deleted. Note there was an internal error afterwards. The team has been cut a ticket. No more action is necessary.
                                </Fragment>;
            }

            // Display error flashbar
            appendNotification({
                content:
                    <Fragment>
                        {errorMessage}
                    </Fragment>,
                type: 'error',
                dismissible: true,
                dismissLabel: 'Dismiss message'
            });

            setVisible(false);
        });
    }

    let cancelDelete = () => {
        setVisible(false);

        if (props.cancel) {
            props.cancel()
        }
    }
    
    // Populate engagement detail info
    useMemo(async () => {
        // Check against undefined since empty string is valid value
        if (props.ctiName === undefined || props.engagement.primaryResolverGroup === undefined) {
            const loadedEngagement: Engagement = await client.get(`/ts/engagements/${props.engagement.id}`) as Engagement;

            setCTI(`AWS Professional Services → ${loadedEngagement.segment} → ${loadedEngagement.primaryResolverGroup}`);
            setRg(loadedEngagement.primaryResolverGroup);
            setConfirming(false);
        }
    }, [props.engagement]);

    useEffect(() => {
        if (props.visible) {
            setVisible(true)
        }
        else {
            setVisible(false)
        }
    }, [props.visible]);

    useEffect(() => {
        setCTI(props.ctiName)
    }, [props.ctiName]);

    useEffect(() => {
        setRg(props.engagement.primaryResolverGroup)
    }, [props.engagement.primaryResolverGroup]);


    // Chooses whether to render as icon or button, only rendered when modalOnly is set to false
    const renderedComponent = (props.isRenderedAsIcon?
            <Box color='text-label'>
                <SpaceBetween size="s" direction="horizontal">
                    <CTIRGStatusLabel engagement={props.engagement}/>
                    <SpaceBetween size="xxxs" direction="horizontal">
                        <Button id={`DeleteCTIRG-${props.engagement.id}`} iconName='remove' variant='icon' onClick={(event) => setVisible(true)}/>
                        {'Delete'}
                    </SpaceBetween>
                </SpaceBetween>
            </Box>
            :
            <Button id={`DeleteCTIRG-${props.engagement.id}`} variant='primary' onClick={(event) => setVisible(true) }>Delete CTI/Resolver Group</Button>
    )

    return  (

        <Box>
            {props.modalOnly? (<Fragment/>): (renderedComponent)
            }

            <Modal
                onDismiss={(event) => {
                    setVisible(false);
                }}
                visible={visible}
                header={<Header>CTI/RG Delete Confirmation</Header>}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button id={`CancelResourceDeletion-${props.engagement.id}`} onClick={(event) => cancelDelete()} variant="link">Cancel</Button>
                            <Button id={`SubmitResourceDeletion-${props.engagement.id}`}
                                onClick={async (event) => await confirmDelete()}
                                disabled={confirming}
                                variant="primary"
                                data-csm-on='click'
                                data-csm-name={TableConstants.AnalyticsEventType.ButtonClick}
                                data-csm-attrs={pinpointLinkDataAttrs(props.engagement, AnalyticsEvent.DeletedCTIRGButton)}>
                                {confirming ? <Spinner/> : <Icon name='remove'/>}
                                {' Delete CTI/RG'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }>
                    <SpaceBetween direction="vertical" size="l">
                        <Box variant="p">
                            Are you sure you want to remove the CTI <b>{cti ? cti : <Spinner />}</b> & the Resolver Group <b>{rg ? rg : <Spinner />} </b>
                            from the following engagement: <b>{props.engagement.name}</b>?
                        </Box>
                        <Box variant="p">
                            <Icon name="status-info" />
                            {' This is a permanent action and open tickets still need to be closed. Remember to close all open tickets in'}
                            <Link 
                                href={`${TableConstants.ALL_OPEN_SIM_C_LINK(props.engagement)}`}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                                data-csm-attrs={pinpointLinkDataAttrs(props.engagement, AnalyticsEvent.AESAPSQTicketsLink)}
                            >
                                {' SIM-Classic'}
                            </Link>
                            {' and '}
                            <Link
                                href={TableConstants.RG_Tickets_LINK(props.engagement.projectId as string)}
                                external={true}
                                data-csm-on='click'
                                data-csm-name={TableConstants.AnalyticsEventType.LinkClick}
                                data-csm-attrs={pinpointLinkDataAttrs(props.engagement, AnalyticsEvent.RGTicketsLink)}
                            >
                                {'SIM-T'}
                            </Link>
                            {' for this engagement before deleting the CTI and Resolver Group.'}
                        </Box>
                    </SpaceBetween>
            </Modal>
        </Box>
    )
}