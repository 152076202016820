import { Engagement, EngagementStatus } from "../../types";

/**
 * TODO: Update isEngagementStageClosed to only use ehiIsActive once EHI Active feature has been approved.
 * [Flag - ff-frontend-ehi-active-20230221]
 *
 * PSA: This condition that helps to determine whether a CTI/RG is deletable or not, has NOT been feature flagged.
 * Reason for this is that the existing condition (i.e. using the three stages shown below) is a subset of ehiIsActive.
 *
 * According to the team that manages the EHI dashboard:
 * "EHI Active is defined as: engagements (projects) which have timecards submitted or approved in last 30days with
 * project stage not in 'Closed', 'Do Not Survey', 'Completed', 'Canceled', 'On Hold', and excludes Complimentary Workshops."
 */
export function isEngagementStageClosed(engagementStage: string | undefined, ehiIsActive: boolean | undefined): boolean {
    let isEngagementStageClosed: boolean = (engagementStage === EngagementStatus.CANCELED ||
        engagementStage === EngagementStatus.CLOSED_DO_NOT_SURVEY ||
        engagementStage === EngagementStatus.COMPLETED);

    // As of 2023/02/23, the ehiIsActive field is not yet available in the production environment. This undefined check
    // handles that situation where the production data has not yet had ehiIsActive added to it.
    if (ehiIsActive !== undefined) isEngagementStageClosed = !(ehiIsActive as boolean);

    return isEngagementStageClosed;
}

export function isEngagementInWarningState(engagement: Engagement, featureFlags: any): boolean {
    return isEngagementInWarningStateForOpenTickets(engagement) ||
        (featureFlags["engagement_closure"]?.enabled && isEngagementDeletionEligible(engagement));
}

export function isEngagementInWarningStateForOpenTickets(engagement: Engagement): boolean {
    // @ts-ignore, warning that openTickets may be undefined but if so, this will evaluate to false with no warning
    return isEngagementStageClosed(engagement.stage, engagement.ehiIsActive) && (engagement?.openTickets && engagement.openTickets > 0);
}

export const isEngagementDeleteable = (userId: string, engagement: Engagement): boolean => {
    // Allow deletion only for special roles on engagmeent
    let userCanDelete: boolean = false;
    if (userId === engagement.manager ||
        userId === engagement.securityBarRaiser ||
        userId === engagement.deliveryPracticeManager ||
        userId === engagement.opportunityOwner) {
        userCanDelete = true;
    }

    return userCanDelete && isEngagementDeletionEligible(engagement);
}

// TODO: Remove this method and uses of it once EHI Active feature has been approved.
// [Flag - ff-frontend-ehi-active-20230221]
export const isEngagementActive = (engagement: Engagement): boolean => {
    return engagement.stage === EngagementStatus.IN_PROGRESS || 
            engagement.stage === EngagementStatus.ON_HOLD ||
            engagement.stage === EngagementStatus.STALLED;
}

export const filterForActiveEngagements = (engagement: Engagement): boolean => {
    return engagement.ehiIsActive as boolean;
};

export const isEngagementDeletionEligible = (engagement: Engagement): boolean => {
    return (isEngagementStageClosed(engagement.stage, engagement.ehiIsActive) && (engagement.activeCTI ? true : false)); // activeCTI is potentially undefined - need to cast to strict boolean
};
